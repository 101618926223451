<template>
    <div v-if="currentInstanceRunningApps.length" class="shepherd-app-sidebar">
        <v-divider></v-divider>
        <div v-for="app in currentInstanceRunningApps" :key="app.sessionId">
            <ApplicationMenuItem :appData="app" />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { sortBy } from 'lodash'

const ApplicationMenuItem = () => import('./TheApplicationSidebarMenuItem.vue')

export default {
    components: {
        ApplicationMenuItem
    },
    computed: {
        ...mapState('snapshotStore', ['applications']),
        ...mapGetters('appStore', ['runningDeployments', 'startingDeployments']),
        currentInstanceRunningApps() {
            return sortBy(
                this.runningDeployments.concat(this.startingDeployments).filter(a => {
                    return (
                        this.applications.filter(aa => {
                            return aa.aid.toString() === a.aid.toString()
                        }).length > 0
                    )
                }),
                'creation_timestamp'
            )
        }
    }
}
</script>
